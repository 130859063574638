<template>
  <div>
    <form class="form" @submit.stop.prevent="formOnsubmit()">
      <!--begin: Wizard Step 1-->
      <div
        class="pb-5"
        data-wizard-type="step-content"
        data-wizard-state="current"
      >
        <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
          {{ title }}
        </h5>

        <b-form-group id="input-group-name">
          <label for="input-name"
            >Nama <span class="text-danger">*</span></label
          >
          <b-form-input
            id="input-name"
            v-model="form.name"
            placeholder="Nama"
          ></b-form-input>
          <small class="text-danger">{{ error.name }}</small>
        </b-form-group>

        <b-form-group id="input-group-ip">
          <label for="input-ip">IP <span class="text-danger">*</span></label>
          <b-form-input
            id="input-ip"
            v-model="form.ip"
            placeholder="IP"
          ></b-form-input>
          <small class="text-danger">{{ error.ip }}</small>
        </b-form-group>

        <b-form-group id="input-group-is_default">
          <!-- <label for="input-is_default"
            >Default <span class="text-danger">*</span></label
          > -->
          <b-form-checkbox v-model="checked" name="check-button" switch>
            Default ? <b>{{ checked ? "Ya" : "Tidak" }}</b>
          </b-form-checkbox>
          <small class="text-danger">{{ error.is_default }}</small>
        </b-form-group>
      </div>
      <!--end: Wizard Step 1-->

      <!--begin: Wizard Actions -->
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/databases/')"
          >
            Batal
          </b-button>
        </div>
      </div>
      <!--end: Wizard Actions -->
    </form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        ip: "",
      },
      checked: true,
      // other
      isRegion: false,
      passwordIsVisible: "password",
    };
  },
  methods: {
    async formOnsubmit() {
      if(this.checked){
        this.form.is_default = 1
      }
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        formData.append(key, value);
      }
      // Make Request
      let response = await module.submit(formData, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/databases/");
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>